import React, { useEffect, useMemo } from "react";
import { StyleSheet } from "react-native";
import { View, Wrapper } from "@components/atoms/Themed";
import NavigationBar from "@components/molecules/NavigationBar";
import SafeAreaView from "@components/molecules/SafeAreaView";
import useExecptionalError from "@hooks/useExecptionalError";
import Upgrade from "@components/organisms/Fallback/FallbackUpdate";
import Maintenance from "@components/organisms/Fallback/FallbackMaintenance";
import Expired from "@components/organisms/Fallback/FallbackExpired";
import Network from "@components/organisms/Fallback/FallbackNetwork";
import ServiceDown from "@components/organisms/Fallback/FallbackServiceDown";
import Unknown from "@components/organisms/Fallback/FallbackUnknown";
import { notify } from "@lib/util/logger";
import { slackWebhookUrl } from "@constants/Env";
import useAccount from "@hooks/useAccount";

export default function Fallback() {
  const { state: error } = useExecptionalError();
  const { state: influencerId } = useAccount();

  const title = useMemo<string>(() => {
    if (error === null) {
      return "エラーが発生しています";
    }
    switch (error.code) {
      case "Upgrade":
        return "アップデートのお知らせ";
      case "Maintenance":
        return "メンテナンスのお知らせ";
      case "Expired":
        return "ログインが切れました";
      case "NetworkError":
        return "オフラインになっています";
      case "ServiceDown":
        return "サービスがダウンしています";
      case "Unknown":
      default:
        return "エラーが発生しています";
    }
  }, [error]);

  // slackに通知する
  useEffect(() => {
    if (error != null && slackWebhookUrl !== "") {
      notify({
        ...error,
        influencerId,
      });
    }
  }, [error, influencerId]);

  if (error === null) {
    return null;
  }

  return (
    <SafeAreaView>
      <View style={styles.container}>
        <NavigationBar title={title} />
        <Wrapper>
          {error.code === "Upgrade" ? (
            <Upgrade />
          ) : error.code === "Maintenance" ? (
            <Maintenance />
          ) : error.code === "Expired" ? (
            <Expired />
          ) : error.code === "NetworkError" ? (
            <Network />
          ) : error.code === "ServiceDown" ? (
            <ServiceDown />
          ) : (
            <Unknown message={error.message} />
          )}
        </Wrapper>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
