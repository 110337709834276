import {
  env,
  appVersion,
  slackWebhookUrl,
  runtimeVersion,
} from "@constants/Env";
import { Platform } from "react-native";
import * as Device from "expo-device";
import axios from "axios";

export async function notify(error: any) {
  if (slackWebhookUrl) {
    try {
      const deviceInfo = {
        os: Device.osName,
        osVersion: Device.osVersion,
        modelName: Device.modelName,
        platform: Platform.OS,
        env,
        appVersion,
        runtimeVersion,
      };

      const payload = {
        text: `Client Error Occurred: ${JSON.stringify(
          error
        )}\nDevice: ${JSON.stringify(deviceInfo)}`,
      };

      // payloadを文字列化して送信
      await axios.post(slackWebhookUrl, JSON.stringify(payload));
    } catch (e) {
      console.error("Failed to send error notification to Slack:", e);
    }
  }
}
